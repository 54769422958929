import React, { useContext, useState } from "react";

function End() {
  return (
    <div>
      <p>
        Congrats spies! You've completed all your tasks. Agent Porter would be
        proud!
      </p>
      <p>
        While you're here, grab a beer! May I recommend Zeus Brewing Company
      </p>
    </div>
  );
}
export default End;
